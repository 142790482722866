// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {RxsYO3EFZ: {pressed: true}};

const cycleOrder = ["RxsYO3EFZ"];

const variantClassNames = {RxsYO3EFZ: "framer-v-1yaix68"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, gfhidH7Dl: image ?? props.gfhidH7Dl ?? {src: new URL("assets/512/mLvEUBA6mZxvereSsxIU5H3jE.png", import.meta.url).href, srcSet: `${new URL("assets/512/mLvEUBA6mZxvereSsxIU5H3jE.png", import.meta.url).href} 401w, ${new URL("assets/mLvEUBA6mZxvereSsxIU5H3jE.png", import.meta.url).href} 448w`}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gfhidH7Dl, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "RxsYO3EFZ", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-cfAhS", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 572, intrinsicWidth: 448, pixelHeight: 572, pixelWidth: 448, sizes: "min(211px, 100vw)", ...toResponsiveImage(gfhidH7Dl)}} className={cx("framer-1yaix68", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"RxsYO3EFZ"} ref={ref} style={{...style}} {...addPropertyOverrides({"RxsYO3EFZ-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-cfAhS [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cfAhS .framer-e9ha06 { display: block; }", ".framer-cfAhS .framer-1yaix68 { height: 269px; position: relative; width: 211px; }", ".framer-cfAhS .framer-v-1yaix68 .framer-1yaix68 { cursor: pointer; }", ".framer-cfAhS.framer-v-1yaix68.pressed .framer-1yaix68 { aspect-ratio: 0.7832167832167832 / 1; height: var(--framer-aspect-ratio-supported, 269px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 269
 * @framerIntrinsicWidth 211
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"QNoOcymh2":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"gfhidH7Dl":"image"}
 * @framerImmutableVariables false
 */
const FramereioepxFqx: React.ComponentType<Props> = withCSS(Component, css, "framer-cfAhS") as typeof Component;
export default FramereioepxFqx;

FramereioepxFqx.displayName = "Bulb";

FramereioepxFqx.defaultProps = {height: 269, width: 211};

addPropertyControls(FramereioepxFqx, {gfhidH7Dl: {__defaultAssetReference: "data:framer/asset-reference,mLvEUBA6mZxvereSsxIU5H3jE.png?originalFilename=0bad33c56d2243be93b05cb09f29d058.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramereioepxFqx, [])